.block-gallery {
    background-color: #464646;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    text-align: center;
    color: $white;

    @include media('>=768px') {
        padding-left: 22px;
        padding-right: 22px;
    }

    h1 {
        font-style: italic;
        font-size: 18px;
        margin-bottom: 17px;
        font-weight: normal;

        @include media('>=768px') {
            font-size: 24px;
        }
    }

    .gallery-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -5px;

        @include media('>=768px') {
            margin: 0 -10px;
        }
    }
    
    .gallery-col {
        max-width: 50%;
        flex: 0 0 50%;
        padding: 0 5px;

        @include media('>=768px') {
            padding: 0 10px;
        }

        @include media('>=768px') {
            max-width: 33.3333333%;
            flex: 0 0 33.3333333%;
        }
    }

    .card-gallery {
        border: 5px solid $white;
        margin-bottom: 15px;

        @include media('>=768px') {
            margin-bottom: 25px;
        }

        a {
            display: block;
            overflow: hidden;
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }

        img {
            width: 100%;
            transition: .3s transform;
        }
    }
}

.text-content {
    padding: 10px 0;

    h4 {
        margin-bottom: 5px;
    }

    &:not(:focus) {
        // position: absolute!important;
        // width: 1px!important;
        // height: 1px!important;
        // padding: 0!important;
        // margin: -1px!important;
        // overflow: hidden!important;
        // clip: rect(0,0,0,0)!important;
        // white-space: nowrap!important;
        // border: 0!important;
    }
}           

.fancybox-caption.fancybox-caption--separate {
    max-width: 800px;
    margin: auto;
    text-align: left;
    border-top: 4px solid #ffb41b;
    border-bottom: 4px solid #ffb41b;
    padding: 5px 10px;
    background: #464646;
    position: absolute;
    top: calc(100% + 20px);
    bottom: auto;

    @include media('>=768px') {
        border-top: 8px solid #ffb41b;
        border-bottom: 8px solid #ffb41b;
        padding: 15px;
    }
}


.fancybox-inner {
    > .fancybox-caption {
        // display: none;
    }

    .fancybox-content {
        .fancybox-caption {
            display: block;
            animation-name: visibilityIn;
            animation-duration: .5s;

            &.fancybox-caption--separate {
                padding-bottom: 5px;
            }
        }

        h4 {
            font-size: 24px;
            margin-bottom: 0;

            @include media('>=768px') {
                font-size: 30px;
            }
        }

        p {
            margin-bottom: 0;
        }
    }
}

@keyframes visibilityIn {
    from {
        opacity: 0;
        visibility: hidden;
    }
  
    to {
        opacity: 1;
        visibility: visible;
    }
  }