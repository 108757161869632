[id="wrapper"] {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 428px;
    margin: auto;
    max-width: 1000px;
    content: " ";
    background: url('images/header_background.gif') no-repeat top center;
    z-index: -1;
    opacity: .3;
  }
}

body {
  background: #292929 url("images/body-bg.jpg");
}

.container {
  @extend %clearfix;

  max-width: $max-width-center-container;
  padding: $padding-container;
  margin: 0 auto;
}

.border-bottom-gray {
  border-bottom: 8px solid #363636;
}

.border-bottom-yellow {
  border-bottom: 8px solid $yellow;
}

.border-top-yellow {
  border-top: 8px solid $yellow;
}

.accessibility {
    &:not(:focus) {
      position: absolute!important;
      width: 1px!important;
      height: 1px!important;
      padding: 0!important;
      margin: -1px!important;
      overflow: hidden!important;
      clip: rect(0,0,0,0)!important;
      white-space: nowrap!important;
      border: 0!important;
  }

  &:focus {
    color: $white;
  }
}

.fancybox-inner {
  right: 15px;
  left: 15px;
}

noscript {
  display: block;
  color: $white;
  padding: 10px;
  font-style: italic;
}