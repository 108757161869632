.block-cards {
	padding-top: 13px;
	padding-left: 15px;
	padding-right: 15px;
	background-color: $gray-700;
}

.cards-row {
	margin-left: -10px;
	margin-right: -10px;

	@include media('>=576px') {
		display: flex;
		justify-content: center;
	}
}

.col-card {
	@include media('>=576px') {
		max-width: 33.3333333%;
		flex: 0 0 33.3333333%;
		padding: 0 10px;
	}
}

