// forms
form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
// input[type='date'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  padding: $input-padding;
  font-size: $input-font-size;
  line-height: $input-line-height;
  font-family: $input-font-family;
  box-sizing: border-box;
  border: $input-border;
  border-radius: $input-border-radius;

  &:not(textarea) {
    height: $input-height;
  }

  &:focus {
    outline: none;
    border-color: $input-focus-border-color;
  }

  @include placeholder {
    color: $placeholder-color;
  }
}

input[type='search']::-webkit-search-cancel-button {-webkit-appearance: none;}

// input[type="search"] {
//   -webkit-appearance: textfield;
// }

textarea {
  resize: vertical;
  vertical-align: top;
  overflow: auto;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  @include animate(background);
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  height: $submit-height;
  padding: $submit-padding;
  font-size: $submit-font-size;
  line-height: $submit-line-height;
  color: $submit-color;
  outline: none;
  cursor: pointer;
  border: $submit-border;
  border-radius: $input-border-radius;
  background: $submit-background;

  &:hover {
    background: $submit-hover-background;
    color: $submit-hover-color;
  }
}