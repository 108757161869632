.btn {
  @include button-color($btn-background, $btn-color, $btn-border, $btn-hover-background, $btn-hover-border, $btn-hover-color);
  border: $btn-border;
  border-radius: $btn-border-radius;
  display: inline-block;
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  letter-spacing: .03em;
  padding: $btn-padding;
  margin: 0 0 $vertical-rhythm;
  vertical-align: top;
  text-align: center;

  @include media('<tablet') {
    width: 100%;
  }

  @include media('>=desktop') {
    min-width: 152px;
    padding: 14px 30px;
  }

  &-default {
    @include button-color($btn-background, $btn-color, $btn-border, $btn-hover-background, $btn-hover-border, $btn-hover-color);
  }

  &-primary {
    @include button-color($btn-background-primary, $btn-color-primary, $btn-border-primary, $btn-hover-background-primary, $btn-hover-border-primary, $btn-hover-color-primary);
  }

  &-warning {
    @include button-color($btn-background-warning, $btn-color-warning, $btn-border-warning, $btn-hover-background-warning, $btn-hover-border-warning, $btn-hover-color-warning);
  }

  &-sm {
    border-radius: $btn-border-radius-sm;
    padding: 4px 10px;
  }
}

.btn-group {
  display: flex;

  > .btn {
    + .btn {
      border-left: 0;
    }
  }
}

.btn-wrap {
  display: flex;
  flex-wrap: wrap;

  .btn {
    margin: 0 0 10px;
    max-width: 50%;

    +.btn {
      margin-left: 20px;
    }
  }
}
