@charset "UTF-8";
/* ---------------------------------------------------------
   2. General reset
   ---------------------------------------------------------*/
.tagcloud {
  margin: 0;
  padding: 0;
  list-style: none; }

.clearfix:after, dl:after, .widget:after, .search-form:after,
.post-password-form:after, .search-form p:after,
.post-password-form p:after, [id="nav"]:after, .container:after {
  content: '';
  display: block;
  clear: both; }

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden; }

.df-row {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 480px) {
    .df-row.df-rr-ph {
      flex-direction: row-reverse; } }
  @media (min-width: 480px) {
    .df-row.df-aic-ph {
      align-items: center; } }
  @media (min-width: 768px) {
    .df-row.df-rr-sm {
      flex-direction: row-reverse; } }
  @media (min-width: 768px) {
    .df-row.df-aic-sm {
      align-items: center; } }
  @media (min-width: 1024px) {
    .df-row.df-rr-md {
      flex-direction: row-reverse; } }
  @media (min-width: 1024px) {
    .df-row.df-aic-md {
      align-items: center; } }
  @media (min-width: 480px) {
    .df-row .col-ph-1 {
      flex: 1 0 calc(100% / 12 * 1);
      max-width: calc(100% / 12 * 1); } }
  @media (min-width: 480px) {
    .df-row .col-ph-2 {
      flex: 1 0 calc(100% / 12 * 2);
      max-width: calc(100% / 12 * 2); } }
  @media (min-width: 480px) {
    .df-row .col-ph-3 {
      flex: 1 0 calc(100% / 12 * 3);
      max-width: calc(100% / 12 * 3); } }
  @media (min-width: 480px) {
    .df-row .col-ph-4 {
      flex: 1 0 calc(100% / 12 * 4);
      max-width: calc(100% / 12 * 4); } }
  @media (min-width: 480px) {
    .df-row .col-ph-5 {
      flex: 1 0 calc(100% / 12 * 5);
      max-width: calc(100% / 12 * 5); } }
  @media (min-width: 480px) {
    .df-row .col-ph-6 {
      flex: 1 0 calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6); } }
  @media (min-width: 480px) {
    .df-row .col-ph-7 {
      flex: 1 0 calc(100% / 12 * 7);
      max-width: calc(100% / 12 * 7); } }
  @media (min-width: 480px) {
    .df-row .col-ph-8 {
      flex: 1 0 calc(100% / 12 * 8);
      max-width: calc(100% / 12 * 8); } }
  @media (min-width: 480px) {
    .df-row .col-ph-9 {
      flex: 1 0 calc(100% / 12 * 9);
      max-width: calc(100% / 12 * 9); } }
  @media (min-width: 480px) {
    .df-row .col-ph-10 {
      flex: 1 0 calc(100% / 12 * 10);
      max-width: calc(100% / 12 * 10); } }
  @media (min-width: 480px) {
    .df-row .col-ph-11 {
      flex: 1 0 calc(100% / 12 * 11);
      max-width: calc(100% / 12 * 11); } }
  @media (min-width: 480px) {
    .df-row .col-ph-12 {
      flex: 1 0 calc(100% / 12 * 12);
      max-width: calc(100% / 12 * 12); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-0 {
      margin-left: calc(100% / 12 * 0); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-1 {
      margin-left: calc(100% / 12 * 1); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-2 {
      margin-left: calc(100% / 12 * 2); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-3 {
      margin-left: calc(100% / 12 * 3); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-4 {
      margin-left: calc(100% / 12 * 4); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-5 {
      margin-left: calc(100% / 12 * 5); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-6 {
      margin-left: calc(100% / 12 * 6); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-7 {
      margin-left: calc(100% / 12 * 7); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-8 {
      margin-left: calc(100% / 12 * 8); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-9 {
      margin-left: calc(100% / 12 * 9); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-10 {
      margin-left: calc(100% / 12 * 10); } }
  @media (min-width: 480px) {
    .df-row .col-offset-ph-11 {
      margin-left: calc(100% / 12 * 11); } }
  @media (min-width: 768px) {
    .df-row .col-sm-1 {
      flex: 1 0 calc(100% / 12 * 1);
      max-width: calc(100% / 12 * 1); } }
  @media (min-width: 768px) {
    .df-row .col-sm-2 {
      flex: 1 0 calc(100% / 12 * 2);
      max-width: calc(100% / 12 * 2); } }
  @media (min-width: 768px) {
    .df-row .col-sm-3 {
      flex: 1 0 calc(100% / 12 * 3);
      max-width: calc(100% / 12 * 3); } }
  @media (min-width: 768px) {
    .df-row .col-sm-4 {
      flex: 1 0 calc(100% / 12 * 4);
      max-width: calc(100% / 12 * 4); } }
  @media (min-width: 768px) {
    .df-row .col-sm-5 {
      flex: 1 0 calc(100% / 12 * 5);
      max-width: calc(100% / 12 * 5); } }
  @media (min-width: 768px) {
    .df-row .col-sm-6 {
      flex: 1 0 calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6); } }
  @media (min-width: 768px) {
    .df-row .col-sm-7 {
      flex: 1 0 calc(100% / 12 * 7);
      max-width: calc(100% / 12 * 7); } }
  @media (min-width: 768px) {
    .df-row .col-sm-8 {
      flex: 1 0 calc(100% / 12 * 8);
      max-width: calc(100% / 12 * 8); } }
  @media (min-width: 768px) {
    .df-row .col-sm-9 {
      flex: 1 0 calc(100% / 12 * 9);
      max-width: calc(100% / 12 * 9); } }
  @media (min-width: 768px) {
    .df-row .col-sm-10 {
      flex: 1 0 calc(100% / 12 * 10);
      max-width: calc(100% / 12 * 10); } }
  @media (min-width: 768px) {
    .df-row .col-sm-11 {
      flex: 1 0 calc(100% / 12 * 11);
      max-width: calc(100% / 12 * 11); } }
  @media (min-width: 768px) {
    .df-row .col-sm-12 {
      flex: 1 0 calc(100% / 12 * 12);
      max-width: calc(100% / 12 * 12); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-0 {
      margin-left: calc(100% / 12 * 0); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-1 {
      margin-left: calc(100% / 12 * 1); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-2 {
      margin-left: calc(100% / 12 * 2); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-3 {
      margin-left: calc(100% / 12 * 3); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-4 {
      margin-left: calc(100% / 12 * 4); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-5 {
      margin-left: calc(100% / 12 * 5); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-6 {
      margin-left: calc(100% / 12 * 6); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-7 {
      margin-left: calc(100% / 12 * 7); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-8 {
      margin-left: calc(100% / 12 * 8); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-9 {
      margin-left: calc(100% / 12 * 9); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-10 {
      margin-left: calc(100% / 12 * 10); } }
  @media (min-width: 768px) {
    .df-row .col-offset-sm-11 {
      margin-left: calc(100% / 12 * 11); } }
  @media (min-width: 1024px) {
    .df-row .col-md-1 {
      flex: 1 0 calc(100% / 12 * 1);
      max-width: calc(100% / 12 * 1); } }
  @media (min-width: 1024px) {
    .df-row .col-md-2 {
      flex: 1 0 calc(100% / 12 * 2);
      max-width: calc(100% / 12 * 2); } }
  @media (min-width: 1024px) {
    .df-row .col-md-3 {
      flex: 1 0 calc(100% / 12 * 3);
      max-width: calc(100% / 12 * 3); } }
  @media (min-width: 1024px) {
    .df-row .col-md-4 {
      flex: 1 0 calc(100% / 12 * 4);
      max-width: calc(100% / 12 * 4); } }
  @media (min-width: 1024px) {
    .df-row .col-md-5 {
      flex: 1 0 calc(100% / 12 * 5);
      max-width: calc(100% / 12 * 5); } }
  @media (min-width: 1024px) {
    .df-row .col-md-6 {
      flex: 1 0 calc(100% / 12 * 6);
      max-width: calc(100% / 12 * 6); } }
  @media (min-width: 1024px) {
    .df-row .col-md-7 {
      flex: 1 0 calc(100% / 12 * 7);
      max-width: calc(100% / 12 * 7); } }
  @media (min-width: 1024px) {
    .df-row .col-md-8 {
      flex: 1 0 calc(100% / 12 * 8);
      max-width: calc(100% / 12 * 8); } }
  @media (min-width: 1024px) {
    .df-row .col-md-9 {
      flex: 1 0 calc(100% / 12 * 9);
      max-width: calc(100% / 12 * 9); } }
  @media (min-width: 1024px) {
    .df-row .col-md-10 {
      flex: 1 0 calc(100% / 12 * 10);
      max-width: calc(100% / 12 * 10); } }
  @media (min-width: 1024px) {
    .df-row .col-md-11 {
      flex: 1 0 calc(100% / 12 * 11);
      max-width: calc(100% / 12 * 11); } }
  @media (min-width: 1024px) {
    .df-row .col-md-12 {
      flex: 1 0 calc(100% / 12 * 12);
      max-width: calc(100% / 12 * 12); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-0 {
      margin-left: calc(100% / 12 * 0); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-1 {
      margin-left: calc(100% / 12 * 1); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-2 {
      margin-left: calc(100% / 12 * 2); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-3 {
      margin-left: calc(100% / 12 * 3); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-4 {
      margin-left: calc(100% / 12 * 4); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-5 {
      margin-left: calc(100% / 12 * 5); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-6 {
      margin-left: calc(100% / 12 * 6); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-7 {
      margin-left: calc(100% / 12 * 7); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-8 {
      margin-left: calc(100% / 12 * 8); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-9 {
      margin-left: calc(100% / 12 * 9); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-10 {
      margin-left: calc(100% / 12 * 10); } }
  @media (min-width: 1024px) {
    .df-row .col-offset-md-11 {
      margin-left: calc(100% / 12 * 11); } }
  @media (min-width: 768px) {
    .df-row {
      margin: 0 -15px; } }

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

* {
  max-height: 1000000px; }

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  color: #000;
  background: #fff;
  font: 16px/1.2 "Georgia", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0; }
  @media (min-width: 768px) {
    body {
      font-size: 18px;
      line-height: 1.2; } }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top; }

.gm-style img {
  max-width: none; }

@media (max-width: 1023px) {
  iframe {
    width: 100%; } }

.resize-active * {
  transition: none !important; }

ul,
ol,
dl,
p,
h1,
h2,
h3,
h4,
h5,
h6,
address,
form,
table,
blockquote,
applet,
embed,
object,
iframe,
frameset {
  margin: 0 0 20px; }

blockquote {
  border-left: 4px solid #ddd;
  padding-left: 10px;
  margin-left: 0; }

table {
  border-collapse: collapse; }

th,
td {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: left;
  vertical-align: top; }

th {
  text-align: center;
  vertical-align: middle; }

ul,
ol {
  padding-left: 20px; }

ul ul,
ul ol,
ol ul,
ol ol {
  padding-left: 20px;
  margin: 0; }

ul {
  list-style-type: disc; }

dl dt {
  float: left;
  clear: left;
  padding-right: 0.3em;
  font-weight: bold; }

dl dd {
  overflow: hidden; }

pre {
  max-width: 100%;
  overflow: auto; }

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none; }

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 18px;
  font-family: "Georgia", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 0; }
  input[type='text']:not(textarea),
  input[type='tel']:not(textarea),
  input[type='email']:not(textarea),
  input[type='search']:not(textarea),
  input[type='password']:not(textarea),
  input[type='url']:not(textarea),
  textarea:not(textarea) {
    height: 40px; }
  input[type='text']:focus,
  input[type='tel']:focus,
  input[type='email']:focus,
  input[type='search']:focus,
  input[type='password']:focus,
  input[type='url']:focus,
  textarea:focus {
    outline: none;
    border-color: #000; }
  input[type='text']::-webkit-input-placeholder,
  input[type='tel']::-webkit-input-placeholder,
  input[type='email']::-webkit-input-placeholder,
  input[type='search']::-webkit-input-placeholder,
  input[type='password']::-webkit-input-placeholder,
  input[type='url']::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #ddd; }
  input[type='text']::-moz-placeholder,
  input[type='tel']::-moz-placeholder,
  input[type='email']::-moz-placeholder,
  input[type='search']::-moz-placeholder,
  input[type='password']::-moz-placeholder,
  input[type='url']::-moz-placeholder,
  textarea::-moz-placeholder {
    opacity: 1;
    color: #ddd; }
  input[type='text']:-moz-placeholder,
  input[type='tel']:-moz-placeholder,
  input[type='email']:-moz-placeholder,
  input[type='search']:-moz-placeholder,
  input[type='password']:-moz-placeholder,
  input[type='url']:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #ddd; }
  input[type='text']:-ms-input-placeholder,
  input[type='tel']:-ms-input-placeholder,
  input[type='email']:-ms-input-placeholder,
  input[type='search']:-ms-input-placeholder,
  input[type='password']:-ms-input-placeholder,
  input[type='url']:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #ddd; }
  input[type='text'].placeholder,
  input[type='tel'].placeholder,
  input[type='email'].placeholder,
  input[type='search'].placeholder,
  input[type='password'].placeholder,
  input[type='url'].placeholder,
  textarea.placeholder {
    color: #ddd; }

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none; }

textarea {
  resize: vertical;
  vertical-align: top;
  overflow: auto; }

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  transition: background 0.5s ease-in-out;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  height: 40px;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  outline: none;
  cursor: pointer;
  border: #000;
  border-radius: 0;
  background: #000; }
  button:hover,
  input[type='button']:hover,
  input[type='reset']:hover,
  input[type='file']:hover,
  input[type='submit']:hover {
    background: black;
    color: #fff; }

.nav-links,
.navigation,
.navigation-comments, .navigation-single {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px; }

.nav-links .next,
.nav-links .prev,
.navigation .next,
.navigation .prev,
.navigation-comments .next,
.navigation-comments .prev, .navigation-single .next a,
.navigation-single .prev a {
  background: #000;
  color: #fff;
  border: #000;
  padding: 10px 20px;
  font-size: 15px;
  line-height: 17px; }
  .nav-links .next:hover,
  .nav-links .prev:hover,
  .navigation .next:hover,
  .navigation .prev:hover,
  .navigation-comments .next:hover,
  .navigation-comments .prev:hover, .navigation-single .next a:hover,
  .navigation-single .prev a:hover {
    background: black;
    color: #fff; }

@media (min-width: 768px) {
  [id="twocolumns"] {
    display: flex; } }

[id="content"] {
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (min-width: 768px) {
    [id="content"] {
      width: 70%; } }
  [id="content"]:only-child {
    width: 100%; }

[id="sidebar"] {
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (min-width: 768px) {
    [id="sidebar"] {
      width: 30%;
      padding-left: 30px; } }

.widget {
  margin-bottom: 20px; }
  .widget select {
    min-width: 150px;
    width: 100%; }

.search-form,
.post-password-form {
  margin-bottom: 10px; }
  .search-form label,
  .post-password-form label {
    display: block; }
  .search-form input,
  .post-password-form input {
    float: left;
    margin-right: 5px; }
    @media (min-width: 768px) {
      [id="sidebar"] .search-form input, [id="sidebar"]
      .post-password-form input {
        width: 100%;
        margin: 0 0 5px; } }
    .search-form input[type='submit'],
    .post-password-form input[type='submit'] {
      margin: 0; }

.nav-links,
.navigation,
.navigation-comments {
  position: relative; }
  .nav-links .screen-reader-text,
  .navigation .screen-reader-text,
  .navigation-comments .screen-reader-text {
    position: absolute;
    left: -99999px; }
  .nav-links .page-numbers,
  .navigation .page-numbers,
  .navigation-comments .page-numbers {
    margin: 0 3px; }
    .nav-links .page-numbers:first-child,
    .navigation .page-numbers:first-child,
    .navigation-comments .page-numbers:first-child {
      margin-left: 0; }
    .nav-links .page-numbers:last-child,
    .navigation .page-numbers:last-child,
    .navigation-comments .page-numbers:last-child {
      margin-right: 0; }
  .nav-links .next,
  .nav-links .prev,
  .navigation .next,
  .navigation .prev,
  .navigation-comments .next,
  .navigation-comments .prev {
    transition: background 0.5s ease-in-out;
    max-width: 49%; }
    @media (max-width: 767px) {
      .nav-links .next,
      .nav-links .prev,
      .navigation .next,
      .navigation .prev,
      .navigation-comments .next,
      .navigation-comments .prev {
        font-size: 0; }
        .nav-links .next:after,
        .nav-links .prev:after,
        .navigation .next:after,
        .navigation .prev:after,
        .navigation-comments .next:after,
        .navigation-comments .prev:after {
          content: '»';
          font-size: 15px; } }
  @media (max-width: 767px) {
    .nav-links .prev:after,
    .navigation .prev:after,
    .navigation-comments .prev:after {
      content: '«'; } }

.navigation-single {
  width: 100%; }
  .navigation-single .next,
  .navigation-single .prev {
    max-width: 49%;
    display: flex;
    align-items: center; }
    .navigation-single .next a,
    .navigation-single .prev a {
      transition: background 0.5s ease-in-out;
      display: block; }
      @media (max-width: 767px) {
        .navigation-single .next a,
        .navigation-single .prev a {
          font-size: 0; }
          .navigation-single .next a:after,
          .navigation-single .prev a:after {
            content: '»';
            font-size: 15px; } }
  @media (max-width: 767px) {
    .navigation-single .prev a:after {
      content: '«'; } }

.navigation-single {
  justify-content: space-between;
  align-items: stretch; }
  .navigation-single .next,
  .navigation-single .prev {
    padding: 0;
    margin: 0;
    max-width: 49%;
    display: flex;
    align-items: center;
    border: 0; }
    .navigation-single .next a,
    .navigation-single .prev a {
      padding: 10px 20px;
      border: #000; }

.comment-form label {
  display: block;
  padding-bottom: 4px; }

.comment-form input,
.comment-form textarea {
  margin: 0 4px 0 0;
  width: 100%; }
  @media (min-width: 768px) {
    .comment-form input,
    .comment-form textarea {
      max-width: 300px; } }
  .comment-form input[type='submit'],
  .comment-form textarea[type='submit'] {
    display: block;
    width: auto; }

.comment-form textarea {
  overflow: auto;
  margin: 0;
  height: 120px;
  min-height: 120px; }

.commentlist {
  margin: 0 0 1.2em; }
  .commentlist .edit-link {
    margin: 0; }
  .commentlist .avatar-holder {
    float: left;
    margin: 0 1.2em 4px 0; }

.commentlist-item .commentlist-item {
  padding: 0; }
  @media (min-width: 768px) {
    .commentlist-item .commentlist-item {
      padding: 0 0 0 2em; } }

.comment,
.commentlist-holder {
  overflow: hidden; }

.commentlist-item .commentlist-item,
.commentlist-item + .commentlist-item {
  padding-top: 20px; }

.widget_calendar th,
.widget_calendar td {
  text-align: center; }

.widget_calendar #prev {
  text-align: left; }

.widget_calendar #next {
  text-align: right; }

#wp-calendar {
  width: 100%; }

.wp-caption {
  max-width: 100%;
  clear: both;
  border: #000;
  padding: 4px;
  margin-bottom: 20px;
  text-align: center; }
  .wp-caption img {
    margin-bottom: 4px; }
  .wp-caption p {
    margin: 0; }

.aligncenter {
  margin: 0 auto 20px; }

img.aligncenter {
  display: block;
  margin: 0 auto; }

.alignleft {
  float: left;
  margin: 0 1.2em 4px 0; }

.alignright {
  float: right;
  margin: 0 0 4px 1.2em; }

.mejs-container {
  margin-bottom: 20px; }

.wp-caption-text,
.gallery,
.alignnone,
.gallery-caption,
.sticky,
.bypostauthor {
  height: auto; }

.gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .gallery .gallery-item {
    margin: 0;
    padding: 0 10px 10px; }
    .gallery .gallery-item dt {
      float: none;
      width: 100%;
      margin: 0 0 10px;
      padding: 0; }

.gallery-columns-2 .gallery-item {
  max-width: 50%;
  width: 50%; }

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
  width: 33.33%; }

.tagcloud {
  display: flex;
  flex-wrap: wrap; }

.comment-list .comment-body {
  margin-bottom: 20px; }

.comment-author img {
  margin-right: 10px; }

h1,
.h1 {
  font-size: 20px; }

h2,
.h2 {
  font-size: 16px; }

h3,
.h3 {
  font-size: 16px; }

h4,
.h4 {
  font-size: 14px; }

h5,
.h5 {
  font-size: 14px; }

h6,
.h6 {
  font-size: 14px; }

@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: 40px; }
  h2,
  .h2 {
    font-size: 30px; }
  h3,
  .h3 {
    font-size: 20px; }
  h4,
  .h4 {
    font-size: 20px; }
  h5,
  .h5 {
    font-size: 18px; }
  h6,
  .h6 {
    font-size: 16px; } }

@media (min-width: 1024px) {
  h1,
  .h1 {
    font-size: 40px; }
  h2,
  .h2 {
    font-size: 30px; }
  h3,
  .h3 {
    font-size: 24px; }
  h4,
  .h4 {
    font-size: 20px; }
  h5,
  .h5 {
    font-size: 18px; }
  h6,
  .h6 {
    font-size: 16px; } }

@media (min-width: 1300px) {
  h1,
  .h1 {
    font-size: 40px; }
  h2,
  .h2 {
    font-size: 30px; }
  h3,
  .h3 {
    font-size: 24px; }
  h4,
  .h4 {
    font-size: 20px; }
  h5,
  .h5 {
    font-size: 18px; }
  h6,
  .h6 {
    font-size: 16px; } }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: "Georgia", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
  color: inherit;
  font-weight: bold;
  margin: 0 0 10px; }
  @media (min-width: 768px) {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6,
    .h {
      margin-bottom: 20px; } }

p {
  margin: 0 0 20px; }

a {
  color: #007bff;
  text-decoration: none; }
  a:hover, a:focus {
    color: #000;
    text-decoration: none; }

a[href*="mailto:"] {
  word-wrap: break-word; }

[id="header"] {
  position: relative;
  background: transparent;
  padding: 20px 0;
  z-index: 2; }
  @media (min-width: 768px) {
    [id="header"] {
      padding: 25px 0 20px 0; } }
  [id="header"] .container {
    max-width: 960px; }

@media (min-width: 480px) {
  .header-holder {
    display: flex;
    justify-content: space-between; } }

.logo {
  width: 200px;
  min-width: 100px;
  margin-right: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px; }
  @media (min-width: 480px) {
    .logo {
      margin: 0; } }
  @media (min-width: 576px) {
    .logo {
      width: 300px; } }
  @media (min-width: 1024px) {
    .logo {
      width: 426px; } }

.address-block {
  font-size: 12px;
  font-style: italic;
  color: #fff;
  text-align: center;
  margin-bottom: 0; }
  @media (min-width: 480px) {
    .address-block {
      text-align: right; } }
  @media (min-width: 1024px) {
    .address-block {
      font-size: 14px; } }
  .address-block > p {
    margin-bottom: 0; }
    @media (min-width: 1024px) {
      .address-block > p:first-child {
        font-size: 18px; } }
  .address-block a {
    color: inherit;
    transition: .3s opacity; }
    .address-block a:hover {
      opacity: .8; }
  .address-block .link-info {
    padding-top: 10px; }
    @media (min-width: 480px) {
      .address-block .link-info {
        padding-top: 20px; } }
    .address-block .link-info p {
      margin-bottom: 10px; }
      @media (min-width: 480px) {
        .address-block .link-info p {
          margin-bottom: 15px; } }
      .address-block .link-info p:last-child {
        margin-bottom: 0; }

[id="nav"] {
  flex-grow: 1; }

.btn {
  background: #000;
  color: #fff;
  border: #000;
  border: #000;
  border-radius: 0;
  display: inline-block;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: .03em;
  padding: 10px 20px;
  margin: 0 0 20px;
  vertical-align: top;
  text-align: center; }
  .btn:hover {
    background: black;
    color: #fff;
    border: black; }
  @media (max-width: 767px) {
    .btn {
      width: 100%; } }
  @media (min-width: 1024px) {
    .btn {
      min-width: 152px;
      padding: 14px 30px; } }
  .btn-default {
    background: #000;
    color: #fff;
    border: #000; }
    .btn-default:hover {
      background: black;
      color: #fff;
      border: black; }
  .btn-primary {
    background: #007bff;
    color: #fff;
    border: #007bff; }
    .btn-primary:hover {
      background: #0062cc;
      color: #fff;
      border: #0062cc; }
  .btn-warning {
    background: #ffb41b;
    color: #fff;
    border: #ffb41b; }
    .btn-warning:hover {
      background: #e79b00;
      color: #fff;
      border: #e79b00; }
  .btn-sm {
    border-radius: 0;
    padding: 4px 10px; }

.btn-group {
  display: flex; }
  .btn-group > .btn + .btn {
    border-left: 0; }

.btn-wrap {
  display: flex;
  flex-wrap: wrap; }
  .btn-wrap .btn {
    margin: 0 0 10px;
    max-width: 50%; }
    .btn-wrap .btn + .btn {
      margin-left: 20px; }

.section-banner {
  display: flex;
  background-size: cover;
  background-position: center;
  padding: 20px 0; }
  .section-banner > * {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; }
  .section-banner .banner-text {
    color: #fff; }
    @media (min-width: 768px) {
      .section-banner .banner-text {
        max-width: 70%; } }
  .section-banner .title {
    margin-bottom: 10px; }

.heading-text {
  padding: 20px 15px 23px;
  background-color: #464646;
  font-size: 14px;
  color: #fff;
  font-style: italic;
  text-align: center; }
  @media (min-width: 480px) {
    .heading-text {
      font-size: 18px; } }
  @media (min-width: 768px) {
    .heading-text {
      padding: 27px 15px 30px; } }
  .heading-text p {
    max-width: 765px;
    margin: 0 auto; }

.block-cards {
  padding-top: 13px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #464646; }

.cards-row {
  margin-left: -10px;
  margin-right: -10px; }
  @media (min-width: 576px) {
    .cards-row {
      display: flex;
      justify-content: center; } }

@media (min-width: 576px) {
  .col-card {
    max-width: 33.3333333%;
    flex: 0 0 33.3333333%;
    padding: 0 10px; } }

.card {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  overflow: hidden;
  max-width: 260px;
  margin-left: auto;
  margin-right: auto; }
  .card .content {
    padding-top: 15px; }
    .card .content h3 {
      margin-bottom: 3px; }
    .card .content p {
      margin-bottom: 0; }
  .card a {
    color: inherit; }

.popup-holder {
  position: relative;
  overflow: hidden;
  height: 0; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

/* Fix IE11 */
.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0; }

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996; }

.fancybox-caption--separate {
  margin-top: -50px; }

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-close-small {
    right: -6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px; } }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

.block-gallery {
  background-color: #464646;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  text-align: center;
  color: #fff; }
  @media (min-width: 768px) {
    .block-gallery {
      padding-left: 22px;
      padding-right: 22px; } }
  .block-gallery h1 {
    font-style: italic;
    font-size: 18px;
    margin-bottom: 17px;
    font-weight: normal; }
    @media (min-width: 768px) {
      .block-gallery h1 {
        font-size: 24px; } }
  .block-gallery .gallery-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -5px; }
    @media (min-width: 768px) {
      .block-gallery .gallery-row {
        margin: 0 -10px; } }
  .block-gallery .gallery-col {
    max-width: 50%;
    flex: 0 0 50%;
    padding: 0 5px; }
    @media (min-width: 768px) {
      .block-gallery .gallery-col {
        padding: 0 10px; } }
    @media (min-width: 768px) {
      .block-gallery .gallery-col {
        max-width: 33.3333333%;
        flex: 0 0 33.3333333%; } }
  .block-gallery .card-gallery {
    border: 5px solid #fff;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .block-gallery .card-gallery {
        margin-bottom: 25px; } }
    .block-gallery .card-gallery a {
      display: block;
      overflow: hidden; }
    .block-gallery .card-gallery:hover img {
      transform: scale(1.1); }
    .block-gallery .card-gallery img {
      width: 100%;
      transition: .3s transform; }

.text-content {
  padding: 10px 0; }
  .text-content h4 {
    margin-bottom: 5px; }

.fancybox-caption.fancybox-caption--separate {
  max-width: 800px;
  margin: auto;
  text-align: left;
  border-top: 4px solid #ffb41b;
  border-bottom: 4px solid #ffb41b;
  padding: 5px 10px;
  background: #464646;
  position: absolute;
  top: calc(100% + 20px);
  bottom: auto; }
  @media (min-width: 768px) {
    .fancybox-caption.fancybox-caption--separate {
      border-top: 8px solid #ffb41b;
      border-bottom: 8px solid #ffb41b;
      padding: 15px; } }

.fancybox-inner .fancybox-content .fancybox-caption {
  display: block;
  animation-name: visibilityIn;
  animation-duration: .5s; }
  .fancybox-inner .fancybox-content .fancybox-caption.fancybox-caption--separate {
    padding-bottom: 5px; }

.fancybox-inner .fancybox-content h4 {
  font-size: 24px;
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .fancybox-inner .fancybox-content h4 {
      font-size: 30px; } }

.fancybox-inner .fancybox-content p {
  margin-bottom: 0; }

@keyframes visibilityIn {
  from {
    opacity: 0;
    visibility: hidden; }
  to {
    opacity: 1;
    visibility: visible; } }

/* ---------------------------------------------------------
   4. Сommon site styles
   ---------------------------------------------------------*/
[id="wrapper"] {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1; }
  [id="wrapper"]:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 428px;
    margin: auto;
    max-width: 1000px;
    content: " ";
    background: url("images/header_background.gif") no-repeat top center;
    z-index: -1;
    opacity: .3; }

body {
  background: #292929 url("images/body-bg.jpg"); }

.container {
  max-width: 1030px;
  padding: 0 15px;
  margin: 0 auto; }

.border-bottom-gray {
  border-bottom: 8px solid #363636; }

.border-bottom-yellow {
  border-bottom: 8px solid #ffb41b; }

.border-top-yellow {
  border-top: 8px solid #ffb41b; }

.accessibility:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.accessibility:focus {
  color: #fff; }

.fancybox-inner {
  right: 15px;
  left: 15px; }

noscript {
  display: block;
  color: #fff;
  padding: 10px;
  font-style: italic; }

#footer {
  padding-top: 13px;
  padding-bottom: 25px; }

.copyright {
  text-align: center;
  font-size: 14px;
  color: #fff; }
  .copyright p {
    margin-top: 0;
    margin-bottom: 0; }
