[id="header"] {
    position: relative;
    background: transparent;
    padding: 20px 0;
    z-index: 2;

    @include media('>=768px') {
      padding: 25px 0 20px 0;
    }



    .container {
      max-width: 960px;
    }
}

.header-holder {
  @include media('>=480px') {
    display: flex;
    justify-content: space-between;
  }
}

// page logo styles

.logo {
  width: 200px;
  min-width: 100px;
  margin-right: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;

  @include media('>=480px') {
    margin: 0;
  }

  @include media('>=576px') {
    width: 300px;
  }

  @include media('>=desktop') {
    width: 426px;
  }
}

// address block styles

.address-block {
  font-size: 12px;
  font-style: italic;
  color: $white;
  text-align: center;
  margin-bottom: 0;


  @include media('>=480px') {
    text-align: right;
  }

  @include media('>=desktop') {
    font-size: 14px;
  }

  > p {
    margin-bottom: 0;

    &:first-child {
      @include media('>=desktop') {
        font-size: 18px;
      }
    }
  }

  
  a {
    color: inherit;
    transition: .3s opacity;

    &:hover {
      opacity: .8;
    }
  }

  .link-info {
    padding-top: 10px;

    @include media('>=480px') {
      padding-top: 20px;
    }

    p {
      margin-bottom: 10px;

      @include media('>=480px') {
        margin-bottom: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

[id="nav"] {
  @extend %clearfix;

  flex-grow: 1;
}
