// 1. Embedded fonts styles
// 3. General reset
// 4. Common site styles
// 5. content site styles

// devices viewport width starts at:
$breakpoints: (
  xs-phone: 320px,
  phone: 480px,
  tablet: 768px,
  desktop: 1024px,
  widescreen: 1300px
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

// ligatured operators ≥ ≤

@import 'vendors/include-media';
// @import 'vendors/gravity-default/browsers.min';
// @import 'vendors/gravity-default/formreset.min';
// @import 'vendors/gravity-default/formsmain.min';
// @import 'vendors/gravity-default/readyclass.min';


/* ---------------------------------------------------------
   2. General reset
   ---------------------------------------------------------*/
@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
@import 'base/cms-reset';
@import 'base/forms';
@import 'base/wp-reset';
@import 'base/typography';

// main content styles

// header styles
@import 'layout/header';
@import 'components/buttons';
@import 'components/section-banner';
@import 'components/heading-text';
@import 'components/section-cards';
@import 'components/cards';
@import 'vendors/fancybox';
@import 'components/block-gallery';

/* ---------------------------------------------------------
   4. Сommon site styles
   ---------------------------------------------------------*/
@import 'layout/common';

// footer styles
@import 'layout/footer';