.card {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-align: center;
    color: $white;
    font-size: 14px;
    overflow: hidden;
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;

    .content {
        padding-top: 15px;

        h3 {
            margin-bottom: 3px;
        }

        p {
            margin-bottom: 0;
        }
    }

    a {
        color: inherit;
    }
}