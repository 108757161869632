#footer {
  padding-top: 13px;
  padding-bottom: 25px;
}

.copyright {
  text-align: center;
  font-size: 14px;
  color: $white;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}