.heading-text {
    padding: 20px 15px 23px;
    background-color: $gray-700;
    font-size: 14px;
    color: $white;
    font-style: italic;
    text-align: center;

    @include media('>=480px') {
        font-size: 18px;
    }
    
    @include media('>=768px') {
        padding: 27px 15px 30px;
    }

    p {
        max-width: 765px;
        margin: 0 auto;
    }
}